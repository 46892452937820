import { DownloadIcon } from './download_icon';
import { isDate } from '../../assets/js/functions';

import './format.css';

export const getNumberString = ammount => 
  new Intl.NumberFormat('vi-VN').format(ammount ?? 0);

export const getCurrencyString = ammount => 
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(ammount ?? 0);

export const getDateString = (value, locale = 'vi-VN') => {
  const date = new Date(value);
  if (!isDate(date)) return '';
  return `${new Date(value)?.toLocaleDateString(locale)}`;
};

export const getDateTimeString = (value, locale = 'vi-VN') => {
  const dateOption = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const timeOption = { hour: 'numeric', minute: 'numeric' };
  const date = new Date(value);
  if (!isDate(date)) return '';
  return `${date?.toLocaleDateString(locale, dateOption)} ${date?.toLocaleTimeString(locale, timeOption)}`;
};

export const Currency = ({ children, width = '' }) => {
  const style = { width: width || '100%' };
  return (
    <div style={style} className='format-currency'>
      {getCurrencyString(children)}
    </div>
  );
};

export const Download = ({ link, children = '', className = '' }) => {
  return (
    <div className={`download ${className}`} onClick={() => (window.location.href = link)}>
      <DownloadIcon className='download-icon' />
      {children ?? link ?? <span className='download-link'>{children ?? link}</span>}
    </div>
  );
};
