import React from "react";

import './blog_single.css';

export function BlogSingle () {
    return <div className="blog_sidebar_wrapper blog_single_wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9">
                    <div className="blog-left-section">
                        <div className="blog-section">
                            <div className="agri-blog-image">
                                <img src="https://via.placeholder.com/870x410" alt="image" />
                                <span className="agri_blog_date">jan 06, 2019</span>
                            </div>
                            <div className="agri-blog-content">
                                <h3><a href="blog_single.html">France bans metam-sodium sprays</a></h3>
                                <div className="blog_user">
                                    <div className="user_name">
                                        <img src="images/user.png" alt="image" />
                                        <a href="javascript:;"><span>john doe</span></a>
                                    </div>
                                    <div className="comment_block">
                                        <span><i className="fa fa-comments-o" aria-hidden="true" /></span>
                                        <a href="javascript:;">26 comments</a>
                                    </div>
                                </div>
                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim venia quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit iluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui ofa deserunt mollit anim id est laborum. Sed ut perspiciatis unde.</p>
                                <p className="mt-3">omnis iste natus error sit voluptatem accusantium doloreme laudantiuem totam rem aperiam, eaque ipsa quereae ab illreyio inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemreeo enim ipsam voluptatem quia voluptartrs sit aspernatur aut odit aut fugit architecto beatae vitae dicta sunt explicabo. Nemfhgrw Nemreeo enim ipsam voluptat Nemreeo</p>
                                <div className="single_content_wrapper">
                                    <img src="https://via.placeholder.com/240x199" alt="image" />
                                    <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et doloertfire magna aliqua Ut enim ad minim venia quis nostrud exercitation ullamco labories nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit iluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatatderesf igd noern proident, sunt in culpa qui ofa deserunt mollit anim id est laborum. Sedrersfsare perspiciatis unde omnis iste natus error sit voluptatem accusantium doloreer sfeme ut laudantiuem totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et qsriuasi architecto beatae vitae dicta sunt explicabo. Nemreeo enim ipsam voluptatreem qeuia voluptas sit aspernatur aut odit aut fugit architecto beatae vitae dicta sunt explicaebo.</p>
                                </div>
                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim venia quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit iluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                <p className="mt-3">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui ofa deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloreme laudantiuem totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemreeo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit architecto beatae vitae dicta sunt explicabo. Nemfhgrw</p>
                                <div className="blog_message">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Duis aute irure dolor in reprehenderit in</p>
                                    <img src="images/message_quotes.png" alt="image" />
                                </div>
                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim venia quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit iluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui ofa deserunt mollit anim id est laborum. Sed ut perspiciatis unde.</p>
                            </div>
                            <div className="author_message_box">
                                <div className="author_image">
                                    <img src="https://via.placeholder.com/150x176" alt="image" />
                                </div>
                                <div className="author_content">
                                    <div className="social_name">
                                        <h3>admin by - <span>john doe</span></h3>
                                        <ul>
                                            <li><a href="javascript:;"><span><i className="fa fa-facebook" aria-hidden="true" /></span></a></li>
                                            <li><a href="javascript:;"><span><i className="fa fa-youtube-play" aria-hidden="true" /></span></a></li>
                                            <li><a href="javascript:;"><span><i className="fa fa-twitter" aria-hidden="true" /></span></a></li>
                                            <li><a href="javascript:;"><span><i className="fa fa-linkedin" aria-hidden="true" /></span></a></li>
                                        </ul>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut eer labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                            </div>
                            <div className="blog_single_heading">
                                <h3>comments <span>(03)</span></h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="comment_section">
                                <ul>
                                    <li>
                                        <div className="comment_box">
                                            <div className="comment_image">
                                                <img src="https://via.placeholder.com/60x60" alt="image" />
                                            </div>
                                            <div className="comment_text">
                                                <div className="comment_header">
                                                    <div className="comment_name">
                                                        <h5>sophie isaac</h5>
                                                        <span>july 29, 2019</span>
                                                        <span>08:20am</span>
                                                    </div>
                                                    <a href="javascript:;">reply</a>
                                                </div>
                                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate exercitation ullamco laboris nisi ut aliquip e .</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="comment_box">
                                            <div className="comment_image">
                                                <img src="https://via.placeholder.com/60x60" alt="image" />
                                            </div>
                                            <div className="comment_text">
                                                <div className="comment_header">
                                                    <div className="comment_name">
                                                        <h5>rachel ruth</h5>
                                                        <span>july 29, 2019</span>
                                                        <span>08:20am</span>
                                                    </div>
                                                    <a href="javascript:;">reply</a>
                                                </div>
                                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate exercitation ullamco laboris nisi ut aliquip e .</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <div className="comment_box">
                                                    <div className="comment_image">
                                                        <img src="https://via.placeholder.com/60x60" alt="image" />
                                                    </div>
                                                    <div className="comment_text">
                                                        <div className="comment_header">
                                                            <div className="comment_name">
                                                                <h5>gordon harry</h5>
                                                                <span>july 29, 2019</span>
                                                                <span>08:20am</span>
                                                            </div>
                                                            <a href="javascript:;">reply</a>
                                                        </div>
                                                        <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div className="comment_box">
                                            <div className="comment_image">
                                                <img src="https://via.placeholder.com/60x60" alt="image" />
                                            </div>
                                            <div className="comment_text">
                                                <div className="comment_header">
                                                    <div className="comment_name">
                                                        <h5>james paul</h5>
                                                        <span>july 29, 2019</span>
                                                        <span>08:20am</span>
                                                    </div>
                                                    <a href="javascript:;">reply</a>
                                                </div>
                                                <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate exercitation ullamco laboris nisi ut aliquip e .</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="blog_single_heading">
                                <h3>leave a comments</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="comment_form">
                                <div className="row">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form_block">
                                            <input type="text" className="form_field" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form_block">
                                            <input type="text" className="form_field" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form_block">
                                            <input type="text" className="form_field" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form_block">
                                            <input type="text" className="form_field" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12">
                                        <div className="form_block">
                                            <textarea placeholder="Message" className="form_field" defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12">
                                        <div className="form_block">
                                            <button className="clv_btn">send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="blog_sidebar">
                        <div className="sidebar_block">
                            <div className="sidebar_heading">
                                <h3>search</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="sidebar_search">
                                <input type="text" placeholder="Search here" />
                                <a href="javascript:;"><i className="fa fa-search" aria-hidden="true" /></a>
                            </div>
                        </div>
                        <div className="sidebar_block">
                            <div className="sidebar_heading">
                                <h3>category</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="sidebar_category">
                                <ul>
                                    <li><a href="javascript:;">Organic Farming<span>(12)</span></a></li>
                                    <li><a href="javascript:;">Fresh Organic Mustard<span>(16)</span></a></li>
                                    <li><a href="javascript:;">Organic Green Bell Pepper<span>(156)</span></a></li>
                                    <li><a href="javascript:;">Permaculture<span>(260)</span></a></li>
                                    <li><a href="javascript:;">Precision Farming<span>(96)</span></a></li>
                                    <li><a href="javascript:;">Conservation Agriculture<span>(12)</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar_block">
                            <div className="sidebar_heading">
                                <h3>recent post</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="sidebar_post">
                                <ul>
                                    <li>
                                        <div className="post_image">
                                            <img src="https://via.placeholder.com/75x65" alt="image" />
                                        </div>
                                        <div className="post_content">
                                            <p>jan 06, 2019</p>
                                            <a href="blog_single.html">how neem cells  produce how useful chemicals paste.</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="post_image">
                                            <img src="https://via.placeholder.com/75x65" alt="image" />
                                        </div>
                                        <div className="post_content">
                                            <p>jan 06, 2019</p>
                                            <a href="blog_single.html">how neem cells  produce how useful chemicals paste.</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="post_image">
                                            <img src="https://via.placeholder.com/75x65" alt="image" />
                                        </div>
                                        <div className="post_content">
                                            <p>jan 06, 2019</p>
                                            <a href="blog_single.html">how neem cells  produce how useful chemicals paste.</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar_block">
                            <div className="sidebar_heading">
                                <h3>archives</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="sidebar_category">
                                <ul>
                                    <li><a href="javascript:;">August  2018<span>(12)</span></a></li>
                                    <li><a href="javascript:;">September  2018<span>(16)</span></a></li>
                                    <li><a href="javascript:;">October  2018<span>(156)</span></a></li>
                                    <li><a href="javascript:;">November 2018<span>(260)</span></a></li>
                                    <li><a href="javascript:;">December  2018<span>(96)</span></a></li>
                                    <li><a href="javascript:;">January  2019<span>(12)</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar_block">
                        <div className="sidebar_test_slider">
                            <div className="swiper-container">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="sidebar_test_slide">
                                            <div className="test_slide_image">
                                                <img src="https://via.placeholder.com/65x65" alt="image" />
                                            </div>
                                            <div className="test_slide_content">
                                                <p>Consectetur adipiscing eliti sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ips suspendisse.</p>
                                                <h5>merica ven</h5>
                                                <img src="images/blogside_quote.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="sidebar_test_slide">
                                            <div className="test_slide_image">
                                                <img src="https://via.placeholder.com/65x65" alt="image" />
                                            </div>
                                            <div className="test_slide_content">
                                                <p>incididunt ut labore et dolore magna aliquaConsectetur adipiscing eliti sed do eiusmod tempor . Quis ips suspendisse.</p>
                                                <h5>devid san</h5>
                                                <img src="images/blogside_quote.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_arrow_wrapper">
                                    <span className="sidebar_test_arrow test_left">
                                    <i className="fa fa-angle-left" aria-hidden="true" />
                                    </span>
                                    <span className="sidebar_test_arrow test_right">
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="sidebar_block">
                            <div className="sidebar_heading">
                                <h3>tags</h3>
                                <img src="images/footer_underline.png" alt="image" />
                            </div>
                            <div className="sidebar_tags">
                                <ul>
                                    <li><a href="javascript:;">Agriculture</a></li>
                                    <li><a href="javascript:;">farm</a></li>
                                    <li><a href="javascript:;">Organic</a></li>
                                    <li><a href="javascript:;">egg</a></li>
                                    <li><a href="javascript:;">Permaculture</a></li>
                                    <li><a href="javascript:;">Green</a></li>
                                    <li><a href="javascript:;">garden</a></li>
                                    <li><a href="javascript:;">milk</a></li>
                                    <li><a href="javascript:;">dairy farm</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}