import { useState } from 'react'
import { Currency, getDateTimeString } from '../format/format'
import { isDate, isImageSrc, isNumber } from '../../assets/js/functions'

import './data_list.css'

export function DataList({ headers = headersDefault, items = [], valueKey = '', renderItem = null, onCheck = null }) {
  const [checkedIndexes, setCheckedIndexes] = useState([])

  const check = (e, index) => {
    let newCheckedIndex = [...checkedIndexes]
    const existedIndex = newCheckedIndex.findIndex(i => i === index)
    if (index >= 0 && index < items.length) {
      if (e.target.checked) newCheckedIndex.push(index)
      else newCheckedIndex.splice(existedIndex, 1)
    } else {
      //check all
      if (e.target.checked) newCheckedIndex = items.map((i, idx) => idx)
      else newCheckedIndex = []
    }
    setCheckedIndexes(newCheckedIndex)
    const checkedItems = newCheckedIndex.map(i => items[i])
    onCheck?.(checkedItems)
  }

  const firstValues = Object.values(items[0])

  const renderCheckbox = (index = -1) => {
    let checked = false
    if (index >= 0 && index < items.length) {
      const existedItem = checkedIndexes.find(i => i === index)
      checked = existedItem >= 0
    } else {
      checked = checkedIndexes.length === items.length
    }
    return onCheck && <input type='checkbox' checked={checked} onChange={e => check(e, index)} />
  }

  return (
    <div className='data-list'>
      <div className='headers'>
        <div className='checkbox'>{renderCheckbox()}</div>
        {headers.map((header, i) => {
          const { width, align } = header;
          const style = {
            width,
            justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : align
          }
          return (
            !header.hidden && (
              <div key={header.name} className={`header ${typeof firstValues[i]}`} style={style}>
                {header.name}
              </div>
            )
          )
        })}
      </div>
      <div className='items'>
        {items.map((item, index) => {
          return (
            <div key={item[valueKey] || index} className='item'>
              <div className='checkbox'>{renderCheckbox(index)}</div>
              {renderItem
                ? renderItem(item)
                : Object.entries(item).map(([field, value], i) => {
                    const style = { width: headers[i]?.width }
                    return (
                      !headers[i]?.hidden && (
                        <div key={field} className={typeof value} style={style}>
                        {
                          isNumber(value) && headers[i]?.format === 'VND' ? <Currency>{value}</Currency> 
                          : isDate(value) ? getDateTimeString(value)
                          : isImageSrc(value) ? <img src={value} alt={value} /> 
                          : value
                        }
                        </div>
                      )
                    )
                  })}
            </div>
          )
        })}
      </div>
      {/* <div className='summary'>
        {headers.map((header, i) => {
          const style = { width: header.width }
          return (
            !header.hidden && header.summary && (
              <div key={header.name} className={` ${typeof firstValues[i]}`} style={style}>
                {header.name}
              </div>
            )
          )
        })}
      </div> */}
    </div>
  )
}

const headersDefault = [{ name: 'Column 1', width: 'calc(3rem + 10vw)', format: '', summary: true, hidden: false }]
