import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home, ProductList, Cart, Blog, BlogSingle, About, Contact } from './screens';
import ProductDetail from './screens/product/detail/product_detail'

export const Router = () => (
  <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/products" element={<ProductList/>}>
      <Route path=":productId" element={<ProductDetail/>} />
    </Route>
    <Route path="/news" element="Tin tức" />
    <Route path="/cart" element={<Cart/>} />
    <Route path="/posts" element={<Blog/>}>
      <Route path=":postId" element={<BlogSingle/>} />
    </Route>
    <Route path="/about" element={<About/>} />
    <Route path="/contact" element={<Contact/>} />
    <Route path="*" element={<h2>Nội dung của trang chưa được cập nhật 😌.</h2>} />
  </Routes>
);
