import React from "react";

import './contact.css';

import contact1 from './images/contact1.png';
import contact2 from './images/contact2.png';
import contact3 from './images/contact3.png';


export function Contact () {
    return <div className="contact_container">
        {/* Contact Block  */}
        <div className="contact_blocks_wrapper">
            <div className="container">
                <div className="product_row">
                    <div className="product_column">
                        <div className="org_product_block contact_block">
                            <span />
                            <div className="contact_icon">
                                <img src={contact1} alt="image" />
                            </div>
                            <h4>Liên Hệ với chúng Tôi</h4>
                            <p>+84-0974-123-456</p>
                            <p>+84-0974-456-789</p>
                        </div>
                    </div>
                    <div className="product_column">
                        <div className="org_product_block contact_block">
                            <span />
                            <div className="contact_icon">
                                <img src={contact2} alt="image" />
                            </div>
                            <h4>email</h4>
                            <p>gocviet@gmail.com</p>
                            <p>mrDoan@gmail.com</p>
                        </div>
                    </div>
                    <div className="product_column">
                    <div className="org_product_block contact_block">
                        <span/>
                        <div className="contact_icon">
                            <img src={contact3} alt="image" />
                        </div>
                        <h4>Địa Chỉ</h4>
                        <p>333 Lãnh Binh Thăng P11 </p>
                        <p>Quận 6, TP.HCM</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact Form */}
        <div className="contact_form_wrapper">
            <div className="container">
                <div className="product_row">
                    <div className="product_column_eight">
                        <div className="contact_form_section">
                            <div className="product_row">
                                <div className="contact_form_send_mess">
                                    <h3>gửi tin nhắn cho chúng tôi</h3>
                                </div>
                                <form>
                                    <div className="contact_form_block_input">
                                        <div className="form_block">
                                            <input type="text" name="first_name" className="form_field require" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="contact_form_block_input">
                                        <div className="form_block">
                                            <input type="text" name="last_name" className="form_field require" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="contact_form_block_input">
                                        <div className="form_block">
                                            <input type="text" name="email" className="form_field require" placeholder="Email" data-valid="email" data-error="Email should be valid." />
                                        </div>
                                    </div>
                                    <div className="contact_form_block_input">
                                        <div className="form_block">
                                            <input type="text" name="subject" className="form_field require" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="contact_form_send_mess">
                                        <div className="form_block">
                                            <textarea placeholder="Message" name="message" className="form_field require" defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="contact_form_send_mess">
                                        <div className="form_block">
                                            <button type="button" className="send_mess_btn submitForm" data-type="contact">gửi</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="product_column_four">
                        <div className="working_time_section">
                            <div className="timetable_block">
                                <h5>giờ làm việc</h5>
                                <ul>
                                    <li>
                                        <p>thứ hai</p>
                                        <p>9:30 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>thứ ba</p>
                                        <p>9:00 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>thứ tư</p>
                                        <p>9:45 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>thứ năm</p>
                                        <p>10:30 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>thứ sáu</p>
                                        <p>9:30 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>thứ bảy</p>
                                        <p>9:30 sáng - 6:00 chiều</p>
                                    </li>
                                    <li>
                                        <p>chủ nhật</p>
                                        <p>nghỉ</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="tollfree_block">
                                <h5>số điện thoại công ty</h5>
                                <h3>+84-0974-567-888</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact Map */}
        {/* <div className="contact_map_wrapper">
            <div id="map" />
        </div> */}
    </div>
}