import { LogoImage } from "./logo_image";
import { LogoText } from "./logo_text";

import './logo.css';

export function Logo({ horizontal=false, size='3rem', leafColor='', rootColor='', outlineColor='', color='' }) {
  return <div className={`logo ${horizontal ? 'horizontal' : ''}`}>
    <LogoImage size={size} rootColor={rootColor || color} leafColor={leafColor}/>
    <LogoText width={`calc(${size} * 1.5)`} color={color || rootColor} outlineColor={outlineColor}/>
  </div>
}