import { axiosClient } from '@api';

const dataModel = {
  chat_id: `@${process.env.REACT_APP_TELEGRAM_CHANNEL_ID}`,
  parse_mode: process.env.REACT_APP_TELEGRAM_PARSE_MODE,
};

export const TelegramApi = {
  sendMessage: text => {
    console.log(process.env);
    axiosClient.post('/sendMessage', { ...dataModel, text });
  },
};
