import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Category, Search, Pagination, CategoryUtils } from '../../../components';
import { DataGrid } from '../../../components/data_grid/data_grid';
import { ProductApi, ProductGroupApi } from '@api';
import { getCurrencyString } from '@nmk-ui-components';

import './product_list.css';
import { GTAG_CATEGORIES, GTAG_EVENTS, GTAG_TYPES } from 'src/global/enums/gtag';

export function ProductList() {
  const [apiParams, setApiParams] = useState({ page: 1, pageSize: 12, keyword: '' });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const { productId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ProductGroupApi.getProductGroups().then(rs => setCategories(rs.data));
    fetchProducts(''); // get all products
  }, []);

  useEffect(() => {
    selectedCategory != undefined && fetchProducts(selectedCategory);
  }, [selectedCategory, apiParams]);

  const selectCategory = category => {
    navigate('/products');
    setSelectedCategory(category);
  };

  const selectProduct = product => {
    navigate(`/products/${product?._id}`);
    setSelectedCategory(undefined);
    setCategories(CategoryUtils.deselectAll([...categories]));
    updateGtagEvent(product);
  };

  const fetchProducts = async groupId => {
    const promise = groupId ? ProductGroupApi.getProducts(groupId, apiParams) : ProductApi.getProducts(apiParams);
    const { data, total } = await promise;
    setData(data);
    setTotal(total);
  };

  const updateGtagEvent = ({ _id, name }) => {
    window.gtag(GTAG_TYPES.EVENT, GTAG_EVENTS.PRODUCT_VIEW, {
      event_category: GTAG_CATEGORIES.ECOMMERCE,
      event_label: name,
      product_id: _id,
      value: 1, //hiện tại đang để số lượt xem (là 1 thì không cần cũng được)
    });
  };

  const renderProduct = product => (
    <>
      <img className='item-image' src={product?.img} alt={product?.name} onClick={() => selectProduct(product)} />
      <div className='item-name'>{product?.name}</div>
      <div className='item-price m-b-1'>{getCurrencyString(product?.price)}</div>
    </>
  );

  const renderList = () => (
    <>
      <div className='product_list_search'>
        <h2 className='page-title'>Danh sách sản phẩm</h2>
        <Search fontSize='1rem' placeholder='Nhập từ khóa để tìm kiếm' onSearch={keyword => setApiParams({ ...apiParams, keyword, page: 1 })} />
      </div>
      <DataGrid data={data} total={20} renderItem={renderProduct} />
      <Pagination pageSize={12} total={total} onChangePage={(page, pageSize) => setApiParams({ ...apiParams, page, pageSize })} />
    </>
  );

  return (
    <div className='product-list p-y-3'>
      <div className='product-category'>
        <Category items={categories} onSelect={selectCategory} />
      </div>
      <div className='product-data flex column gap-5'>{productId ? <Outlet /> : renderList()}</div>
    </div>
  );
}
