import axios from "axios";

export { ProductApi } from './product_api';
export { ProductGroupApi } from './product_group_api';
export { TelegramApi } from './telegram_api';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ORIGIN,
  headers: {
    "content-type": "application/json",
  },
});

// await _client.PostAsync < object > (url, data);

// "Origin": "https://api.telegram.org",
//     "ParseMode": "MarkdownV2",
//     "TransactionChannel": {
//       "Id": "hrv_bot_test",
//       "Token": "5354438060:AAF03tsm-ETtQonCjtQnSaW2D-kq0rLGj4E"
//     },

export const API = {
  get: async (url) => {
    const rs = await axiosClient.get(url);
    return rs.data;
  }
}
