import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, Logo } from '../../components'
import { Svg } from '@nmk-ui-components';

import './header.css'

const menu = [
  {
    key: 'home',
    icon: <Svg name='home'/>,
    text: 'Trang chủ',
    path: '/',
  },
  {
    key: 'products',
    icon: <Svg name='seed' width='20px'/>,
    text: 'Sản phẩm',
    path: 'products',
  },
  {
    key: 'posts',
    icon: <Svg name='file-lines'/>,
    text: 'Bài viết',
    path: 'posts',
  },
  // {
  //   key: 'about',
  //   icon: <Svg name='id-card-clip'/>,
  //   text: 'Giới thiệu',
  //   path: 'about',
  // },
  {
    key: 'contact',
    icon: <Svg name='address-card'/>,
    text: 'Liên hệ',
    path: 'contact',
  },
]

export const Header = () => {
  const navigate = useNavigate()
  const [sticky, setSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY >= 100)
      // console.log(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <header className={`${sticky ? 'sticky' : ''} flex center`}>
      <div className='header-content'>
        <div className='logo-menu flex-1 flex space-between'>
          <Logo size='3rem' color='var(--gocviet-green)' outlineColor='var(--gocviet-green-light)' />
          <Menu items={menu} />
        </div>
        <div className='menu-item cart tooltip-wrapper' onClick={() => navigate('/cart')}>
          <Svg name='cart' size='30px'/>
          <span className='tooltip left'>Giỏ hàng</span>
        </div>
      </div>
    </header>
  )
}
