import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './layout'

import '@nmk-ui-components'
import './global/styles/style_vars.css'
import './global/styles/styles.css'
import './global/styles/elements.css'

const container = document.getElementById('root');
const root = createRoot(container) // for automatic batching (React v18)
root.render(
  <BrowserRouter>
    <Layout />
  </BrowserRouter>
);
