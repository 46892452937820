import React from 'react'
import { useLocation } from 'react-router-dom';
import { Router } from '../router'
import { Main } from './body/main'
import { Footer } from './footer/footer'
import { Header } from './header/header'

import './layout.css'
import { useGtagPageview } from 'src/global/functions/hooks';

export const Layout = props => {
  useGtagPageview();
  const { pathname } = useLocation();
  return (
    <div className={`layout-wrapper ${pathname.replaceAll('/','') || 'home'}`}>
      <Header />
      <Main>
        <Router />
      </Main>
      <Footer />
    </div>
  )
}
