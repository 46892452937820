import React, { useState, useRef, useEffect } from 'react'
import { debounce } from 'lodash'
import { Icon } from '../../components'

import './search.css'

export const Search = ({ placeholder, onSearch, useCollapse=false, useButton=false, buttonText='Tìm Kiếm', fontSize }) => {
  const [searchText, setSearchText] = useState('')

  const debounceTextSearch = useRef(debounce(onSearch, 750))  //it catches a snapshot
  const debounceClickSearch = useRef(debounce(onSearch, 500)) //it catches a snapshot

  useEffect(() => {
    debounceTextSearch.current = debounce(onSearch, 750)
    debounceClickSearch.current = debounce(onSearch, 500)
  }, [onSearch]) //re assign when onSearch changes

  const onChangeText = e => {
    setSearchText(e.target.value)
    if (!useButton) debounceTextSearch?.current(e.target.value) //myOnSearch?.(e.target.value)
  }

  const onClickSearch = () => debounceClickSearch?.current(searchText)

  const hasTextClass = searchText ? 'hasText' : ''
  const searchInputClass = useCollapse ? 'collapsed' : ''
  const searchStyle = { fontSize }

  return (
    <div className={`search ${hasTextClass}`} style={searchStyle}>
      <Icon name='search' width='1vw' height='1vw' className='search-icon' />
      <input className={searchInputClass} 
        placeholder={placeholder} 
        onChange={onChangeText} 
        value={searchText} />
      {useButton && <button onClick={onClickSearch}>{buttonText}</button>}
    </div>
  )
}
