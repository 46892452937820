import React from 'react';
import { Svg } from '@nmk-ui-components';

import './footer.css';

const address = 'Công ty Gốc Việt, 47b1 đường 102 phường Tăng Nhơn Phú A, quận 9, Thủ Đức';
const email = 'nguyenhoangxuandien051984@gmail.com';
const phone = '+84914468454';

export const Footer = props => {
  return (
    <>
      <footer>
        <div className='footer-content flex m-auto'>
          <div className='company-info p-x-4 flex-2'>
            <div className='footer-company-title'>
              <div className='flex y-center gap-2'>
                Gốc Việt 
                <span className='font-size-xs'>~  Nông phẩm sạch vì sức khỏe bà con. </span>
              </div>
              <div className='pc-show'>
                <p className='copyright plastic'>{new Date().getFullYear()} © Gốc Việt.</p>
                <p className='footer-social flex y-center gap-4'>
                  <Svg name='zalo' size='1.75rem' />
                  <Svg name='facebook' size='1.75rem' />
                  <Svg name='youtube' size='1.75rem' />
                </p>
              </div>
            </div>
            <ul className='footer-list-menu'>
              <li className='address'>
                <Svg name='address' size='1rem' className='m-r-2' />
                {address}.
              </li>
              <li className='flex y-center'>
                <Svg name='working-hour' size='1rem' className='m-r-2' />
                Thời gian làm việc: 08:00 - 18:00 hằng ngày
              </li>
              <li className='flex y-center'>
                <Svg name='email' size='0.95rem' className='m-r-2' />
                Email:
                <a href={email} className='m-l-1'>
                  {email}
                </a>
              </li>
              <li className='flex y-center'>
                <Svg name='phone' size='0.75rem' className='m-r-3' />
                Điện thoại:
                <a href={`tel:${phone}`} className='m-l-1'>
                  {phone}
                </a>
              </li>
            </ul>
          </div>
          <div className='p-x-4 flex-1'>
            <p className='footer-contact footer-title'>Chính sách và hỗ trợ</p>
            <ul className='footer-list-menu'>
              <li>Chính sách đổi trả</li>
              <li>Điều khoản & Điều kiện</li>
            </ul>
          </div>
          <div className='p-x-4 flex-1'>
            <p className='footer-contact footer-title'>Phương thức thanh toán</p>
            <ul className='footer-list-menu'>
              <li>Hướng dẫn đặt hàng</li>
              <li>Phương thức vận chuyển</li>
            </ul>
          </div>
          <div className='copyright mobile p-x-4 flex-1 text-center'>
            <p>{new Date().getFullYear()} © Gốc Việt.</p>
            <p className='footer-social flex center gap-4 m-t-2'>
              <Svg name='zalo' size='1.75rem' />
              <Svg name='facebook' size='1.75rem' />
              <Svg name='youtube' size='1.75rem' />
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
