import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Input, Svg, getCurrencyString } from '@nmk-ui-components';
import { ProductApi } from '../../../api';
import { TagSelector } from 'src/components/tag_selector/tag_selector';

import './product_detail.css';
import { GTAG_CATEGORIES, GTAG_EVENTS, GTAG_TYPES } from 'src/global/enums/gtag';

function ProductDetail() {
  const [product, setProduct] = useState();
  const { productId } = useParams();
  const [selectedVariant, setSelectedVariant] = useState({});
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    fetchProductDetail();
  }, [productId]);

  const fetchProductDetail = async () => {
    const data = await ProductApi.getProductDetail(productId);
    const defaultVariant = data.variants?.find(v => v.isDefault);
    defaultVariant.selected = true;
    setSelectedVariant(defaultVariant);
    setProduct(data);
  };

  const selectVariant = variants => {
    setSelectedVariant(variants.find(v => v.selected));
    setProduct({ ...product, variants });
  };

  const addProduct = product => {
    const { _id: product_id, name: product_name } = product;
    const { _id: variant_id, name: variant_name, images, price, unit } = selectedVariant;
    const cartLocal = JSON.parse(localStorage.getItem('cart') || '[]');
    let cartItem = cartLocal.find(item => item.variant_id === variant_id);
    if (cartItem) {
      cartItem.quantity += quantity;
    } else {
      cartItem = {
        product_id, product_name,
        variant_id, variant_name,
        images, price, quantity, unit
      };
      cartLocal.push(cartItem);
      updateGtagEvent(cartItem);
    }
    localStorage.setItem('cart', JSON.stringify(cartLocal));
    setQuantity(0);
  };

  const updateGtagEvent = ({ product_id, product_name, variant_id, quantity }) => {
    window.gtag(GTAG_TYPES.EVENT, GTAG_EVENTS.PRODUCT_ADD, {
      event_category: GTAG_CATEGORIES.ECOMMERCE,
      event_label: product_name,
      product_id, variant_id,
      value: quantity,
    });
  };

  const { price, unit, properties } = selectedVariant;

  return (
    <div className='product-detail p-5'>
      {product ? (
        <>
          <h2 className='page-title'>{product.name}</h2>
          <div className='flex gap-5'>
            <Carousel visibleCount={1} isLoop gap='20px'>
              {selectedVariant.images?.map((src, index) => (
                <div key={`${selectedVariant.name}-img-${index}`} className='product-image'>
                  <img src={src} />
                </div>
              ))}
            </Carousel>
            <div className='info-product-detail flex column space-between'>
              <div className='flex column gap-4'>
                <div className='product-variants flex column gap-2'>
                  <span>Chọn loại:</span>
                  <TagSelector className='width-fit' options={product.variants.map(v => ({ ...v, tooltip: v.description }))} onSelect={selectVariant} />
                </div>
                <div className='product-price'>
                  {getCurrencyString(price)}/{unit}
                </div>
                <div className='product-quantity flex column gap-2'>
                  <label>Số lượng:</label>
                  <div className='flex'>
                    <Svg name='minus' viewBox='90 90 870 850' height='100%' fill='var(--gocviet-red-light)' className='auto-width' onClick={() => setQuantity(!quantity ? quantity : quantity - 1)} />
                    <Input type='number' min={0} style={{ width: '5rem' }} debounceTime={0} value={quantity} onChange={e => setQuantity(e.target.value)} />
                    <Svg name='add' viewBox='70 90 870 850' height='100%' fill='var(--gocviet-green)' className='auto-width' onClick={() => setQuantity(quantity + 1)} />
                  </div>
                </div>
              </div>
              <div className='flex gap-3'>
                <button className='add-to-cart flex-1' onClick={() => addProduct(product)}>
                  <Svg name='cart-plus' size='1.75rem' />
                  <span className='full-width text-center'>Thêm vào giỏ</span>
                </button>
                <button className='add-to-cart buy-now flex-1'>
                  <Svg name='cart-check' size='1.75rem' />
                  <span className='full-width text-center'>Mua ngay</span>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ProductDetail;
