import { React, useEffect, useState } from "react";

export function Pagination({ pageSize, total, onChangePage }) {
  const [activePage, setActivePage] = useState(1);
  const [pages, setPages] = useState([]);

  const calculateRange = () => {
    const range = [];
    const num = Math.ceil(total / pageSize);
    for (let i = 1; i <= num; i++) {
      range.push(i);
    }
    return range;
  };

  useEffect(() => {
    setPages(calculateRange());
  }, [total]);

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    onChangePage?.(newPage, pageSize);
  };

  return (
    total > 0 && (
      <div className="grid-paging">
        {pages?.map?.((page, index) => (
          <span
            key={index}
            className={page === activePage ? "active-pagination" : "pagination"}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </span>
        ))}
      </div>
    )
  );
}
