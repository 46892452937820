import { useEffect, useState } from 'react'
import { Svg } from '@nmk-ui-components';
import { CategoryUtils } from './category_utils';

import './category.css';

export function Category({ items, onSelect }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(items);
  }, [items]);

  const clickItem = (newItem, e) => {
    e.stopPropagation();
    const newList = [...list];
    if (newItem.children?.length) {
      newItem.isExpanded = !newItem.isExpanded;
    }
    else CategoryUtils.selectItem(newItem, newList, onSelect) ;
    setList(newList);
  }

  const render = (categories) => {
    return categories?.map((item, index) => {
      const { _id, name, isExpanded, isSelected, children } = item;
      return <div key={_id || index} className={`category-item ${isSelected ? 'selected' : ''}`} onClick={(e)=>clickItem(item, e)}>
        <div className='flex y-center gap-2'>
          <Svg name={!children?.length ? 'emtpy' : isExpanded ? 'up' : 'down'} size={12} />
          <span className="category-item-title">{name}</span>
        </div>
        {
          children?.length > 0 && 
          <div className={`category-container ${isExpanded ? 'expanded' : ''}`}>
            {render(children)}
          </div>
        }
      </div>
    });
  }

  return (
    <div className='category-container'>
      {render(list)}
    </div>
  )
}
