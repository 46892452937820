import React, { useMemo, useState } from 'react';
import { DataList, Input, Button, Popup, getCurrencyString } from '@nmk-ui-components';
import { TelegramApi } from '@api';

import './cart.css';

const headers = [
  // { name: 'Mã sản phẩm', width: 'calc(5rem + 1vw)', hidden: true },
  { name: 'Sản phẩm', width: '20rem' },
  { name: 'Đơn giá', width: '6rem', align: 'right', format: 'VND' },
  { name: 'Số lượng', width: '5rem', align: 'right' },
  { name: 'Tạm tính', width: '7rem', align: 'right', format: 'VND' },
];

export function Cart() {
  const [selecedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isChangeCustomerInfo, setChangeCustomerInfo] = useState(true);
  const [order, setOrder] = useState({});
  const [popupIsShow, setShowPopup] = useState(false);
  const [orderIsSending, setSendingOrder] = useState(false);

  const cart = JSON.parse(localStorage.getItem('cart') || '[]');

  const selectProducts = products => {
    const initialValue = 0;
    const total = products.reduce((price, currentValue) => price + currentValue.totalPrice, initialValue);
    setTotalPrice(total);
    setSelectedProducts(products);
  };

  const setCustomerName = value => setOrder({ ...order, customer_name: value });
  const setCustomerPhone = value => setOrder({ ...order, phone: value });
  const setCustomerAddress = value => setOrder({ ...order, address: value });

  const sendOrder = () => {
    const customerStr = `Tên khách hàng: \`${order.customer_name}\` \`${order.customer_id ? `\\- ${order.customer_id}` : ''}\``;
    const productStr = selecedProducts.reduce((previousProduct, product) => `${previousProduct} \n    🛒 \`${product.name}\` \`${product.id ? `\\- ${product.id}` : ''}\` `, 'Sản phẩm: ');
    const totalPriceStr = `Tổng tiền: \`${getCurrencyString(totalPrice)}\``;
    const message = `✨*Đơn hàng mới:* \n  » ${customerStr} \n  » ${productStr} \n  » ${totalPriceStr} \n  » Địa chỉ nhận hàng: \`${order.address}\``;
    TelegramApi.sendMessage(message);
  };

  const renderItem = item => {
    const { images, product_name, variant_name, price, unit, quantity } = item;
    return (
      <>
        <div className='item-img-name flex v-center gap-2' style={{ width: headers[0].width }}>
          <img src={images?.[0]} />
          <span className='cart-item-name'>
            {product_name} ({variant_name})
          </span>
        </div>
        <div className='cart-item-price flex x-end' style={{ width: headers[1].width }}>
          {getCurrencyString(price)}
        </div>
        <div className='cart-item-quantity flex x-end' style={{ width: headers[2].width }}>
          {quantity} {unit}
        </div>
        <div className='cart-item-summary flex x-end' style={{ width: headers[3].width }}>
          {getCurrencyString(price * quantity)}
        </div>
      </>
    );
  };

  const popupContent = useMemo(() => {
    const { customer_name, phone, address } = order;
    return <div className='order-confirm flex column gap-3'>
      <div className='flex gap-2'>
        <span className='bold'>Tên khách hàng:</span>
        <span>{customer_name}</span>
      </div>
      <div className='flex gap-2'>
        <span className='bold'>Hàng sẽ được giao tới:</span>
        <span>{address}</span>
      </div>
      <div className='flex gap-2'>
        <span className='bold'>Khi đến nơi, nhân viên giao hàng sẽ liên hệ qua số điện thoại:</span>
        <span>{phone}</span>
      </div>
    </div>
  }, [order]);

  return (
    <div className='cart-container'>
      <h2 className='page-title'>Giỏ hàng</h2>
      <div className='cart flex space-between gap-3'>
        <div className='cart-data flex-3'>{cart.length === 0 ? <h3 className='cart-items-empty'>Chưa có sản phẩm nào.</h3> : <DataList headers={headers} items={cart} renderItem={renderItem} valueKey='id' onCheck={selectProducts} />}</div>
        <div className='order-deal flex column x-end gap-2'>
          <div className='flex gap-1'>
            <Input type='checkbox' label='Thay đổi' value={isChangeCustomerInfo} onChange={setChangeCustomerInfo} />
          </div>
          <div className='order-info flex column gap-2'>
            <Input label='Họ và tên' disabled={!isChangeCustomerInfo} onChange={setCustomerName} />
            <Input label='Số điện thoại' disabled={!isChangeCustomerInfo} onChange={setCustomerPhone} />
            <Input label='Địa chỉ nhận hàng' type='textarea' rows={2} disabled={!isChangeCustomerInfo} onChange={setCustomerAddress} />
          </div>
          <div className='order_result'>Tổng tiền: {getCurrencyString(totalPrice)}</div>
          <Button type='success' className='margin-top-5' onClick={() => setShowPopup(true)}>
            Đặt hàng
          </Button>
        </div>
      </div>
      <Popup visible={popupIsShow} title='Xác nhận đặt hàng' content={popupContent} onAccept={sendOrder} successAccept cancelText='Hủy' onClose={() => setShowPopup(false)} />
    </div>
  );
}
