
const selectItem = (item, categories, callback) => {
  for (const c of categories) {
    if (c._id === item._id) {
      c.isSelected = true;
      callback?.(item._id);
    }
    else {
      c.isSelected = false;
      c.children?.length && selectItem(item, c.children, callback);
    }
  }
}

const deselectAll = (category) => {
  category?.forEach(c => {
    c.isSelected = false;
    deselectAll(c.children);
  });
  return category;
}

export const CategoryUtils = {
  selectItem, deselectAll,
};