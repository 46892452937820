import { useEffect, useState, useRef, useMemo } from 'react';
import { isEqual } from 'lodash';
import { noAction } from '..';
import { Icon } from '../icon/icon';
import { findImage, movechildren } from '../../assets/js/functions';

import './carousel.css';

export function Carousel({ children = [], visibleCount = 3, showThumbnail = false, isLoop = false, 
  autoplayInterval = 0, transition = '500ms', width = '', height = '', className = '', onBack = noAction, onForward = noAction 
}) {
  const ref = useRef(null);
  const refInterval = useRef(null);
  const refcount = useRef(0);

  const [gap, setGap] = useState(0);
  const [thumbImages, setThumbImages] = useState([]);
  const [count, setcount] = useState(0);
  const [action, setAction] = useState('');

  useEffect(() => {
    const images = children.map(findImage);
    if (!isEqual(images, thumbImages)) setThumbImages(images);
  }, [children]);

  useEffect(() => {
    const gapNumber = parseInt(window.getComputedStyle(ref.current).gap.replace('px', ''));
    setGap(gapNumber);
  }, [ref]);

  useEffect(() => {
    if (autoplayInterval > 0) refInterval.current = setInterval(forward, autoplayInterval);
    else clearInterval(refInterval.current);
    return () => clearInterval(refInterval.current);
  }, [autoplayInterval]);

  useEffect(() => {
    refcount.current = count;
  }, [count]);

  const back = () => {
    let count = refcount.current;
    if (count > 0) setcount(count - 1);
    else if (isLoop) setcount(children.length - 1);
    else return;
    setAction('back');
    onBack?.();
  };
  const forward = () => {
    let count = refcount.current;
    if (count < children.length - 1) setcount(count + 1);
    else if (isLoop) setcount(count === children.length - 2 ? (count + 1) : 0);
    else return;
    setAction('forward');
    onForward?.();
  };

  const transformation = useMemo(
    () => {
      const n = children.length
      const rightCount = (count + n-1) % n //số phần tử dịch phải
      const leftCount = n - rightCount; //số phần tử dịch trái
      const leftOffset = rightCount + 1; //độ dịch trái
      const rightOffset = n - leftOffset; //độ dịch phải
      const style = {
        minWidth: `calc((100% - ${gap * (visibleCount - 1)}px) / ${visibleCount})`,
        transition
      }
      if (n === 1 || (n === 2 && !count)) return { styleLeft: style };
      return {
        leftCount,
        rightCount,
        styleLeft: {
          transform: `translateX(calc(${-leftOffset} * (100% + ${gap}px)))`,
          ...style,
        },
        styleRight: {
          transform: `translateX(calc(${rightOffset} * (100% + ${gap}px)))`,
          ...style,
        }
      }
    }, [children.length, count, gap, visibleCount, transition]
  );

  return (
    <div className={`carousel ${className}`} style={{ width, height }}>
      <div ref={ref} className='flex gap-2 full-height'>
        {children.map((item, index) => {
          const n = children.length;
          const { rightCount, styleRight, styleLeft } = transformation;
          let style = { ...(index < rightCount ? styleRight : styleLeft) };
          if(n > 2 && index === (count + n - (action === 'back' ? 1 : 2)) % n) { //Phần tử được đưa ngược về cuối thì phải ẩn đi để không thấy nó lướt qua.
            style.visibility = 'hidden';
            style.transition = 'none';
          }
          const isVisible = count <= index && index <= count + visibleCount - 1;
          return (
            <div key={item.key || index} style={style} className={`carousel-item ${isVisible ? 'visible' : ''} flex center`}>
              {item}
            </div>
          );
        })}
      </div>
      <div className='carousel-buttons flex space-between'>
        <Icon type='left' onClick={back}></Icon>
        <Icon type='right' onClick={forward}></Icon>
      </div>
      {showThumbnail && (
        <div className='carousel-thumb flex gap-2'>
          {thumbImages.map((src, index) => (
            <div key={index} className='carousel-thumb-item flex center'>
              <img src={src} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

/*
1 2 3 4 5
count = 0: 5 [1 2 3] 4 >> x(0), x(0), x(0), x(0), x(-5)
count = 4: 4 [5 1 2] 3 >> x(1), x(1), x(1), x(-4), x(-4)
count = 3: 3 [4 5 1] 2 >> x(2), x(2), x(-3), x(-3), x(-3)
count = 2: 2 [3 4 5] 1 >> x(3), x(-2), x(-2), x(-2), x(-2)
count = 1: 1 [2 3 4] 5 >> x(-1), x(-1), x(-1), x(-1), x(-1)

số phần tử dịch phải: (count + n-1) % n
số phần tử dịch trái: n - số phần tử dịch phải

độ dịch trái = số phần tử dịch phải + 1
độ dịch phải = n - độ dịch trái

1 2 3 4 5
count = 0: 5 [1 2 3] 4 >> x(0), x(0), x(0), x(0), x(-5)
count = 1: 1 [2 3 4] 5 >> x(-1), x(-1), x(-1), x(-1), x(-1)
count = 2: 2 [3 4 5] 1 >> x(3), x(-2), x(-2), x(-2), x(-2)
count = 3: 3 [4 5 1] 2 >> x(2), x(2), x(-3), x(-3), x(-3)
count = 4: 4 [5 1 2] 3 >> x(1), x(1), x(1), x(-4), x(-4)
*/