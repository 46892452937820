import { isEqual } from 'lodash';
import { Tag } from './tag/tag';
import './tag_selector.css';

export const TagSelector = ({ options, multiple, className='flex center gap-3', onSelect }) => {
  const handleClick = (tag) => {
    const tags = [...options];
    tags.forEach(t => {
      t.selected = multiple ? (isEqual(t, tag) | t.selected) : isEqual(t, tag);
    });
    onSelect?.(tags);
  }
  return <div className={`tag-selector ${className}`}>
    { options.map((o,i) => <Tag key={o._id || i} {...o} onClick={()=>handleClick(o)}/>) }
  </div>
};