import React from "react";

export const MenuItem = (props) => {
  return (
    <div className="menu-item">
      <div className="menu-item-icon">
        {props.icon}
        <span>{props.title}</span>
      </div>
    </div>
  );
};
