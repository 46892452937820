import { useMemo } from 'react';

import data from './svg-data.json';

import './svg.css';

export const Svg = ({ name, viewBox, size = '', width = '20px', height = '20px', fill = 'currentColor', className = '', onClick }) => {
  const { viewBox: defaultViewBox, paths } = useMemo(() => {
    return data.find(i => i.name === name) ?? {};
  }, [name]);

  return (
    <svg viewBox={viewBox || defaultViewBox || '0 0 20 20'} 
      width={size || width} height={size || height} 
      fill={fill} className={className} onClick={onClick}>
      {paths?.map((p, i) => (
        <path key={`${name}-p-${i}`} d={p.d} fill={p.fill} fillRule={p.fillRule} clipRule={p.clipRule} />
      ))}
    </svg>
  );
};
