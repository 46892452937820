import { DataGridItem } from './data_grid_item/data_grid_item'
import './data_grid.css'

export function DataGrid({ data, renderItem }) {
  return <div className='data-grid'>
  {
    data?.length
    ? <div className='grid-items'>
      {
        data.map(
          (item, index) => <DataGridItem
            key={item._id || index}
            {...item}
          >
            {renderItem(item)}
          </DataGridItem>)
      }
    </div>
    : <div className='flex center'>
      <span className='empty-table'>Không tìm thấy dữ liệu</span>
    </div>
  }
  </div>
}
