import React from 'react';

import './blog.css';

import footerUnderline from './images/footer_underline.png';
import user1 from './images/user1.JPEG';
import user2 from './images/user2.jpg';
import user3 from './images/user3.jpg';

import blogImg5 from './images/blog5.jpg';
import blogImg6 from './images/blog6.jpg';
import blogImg7 from './images/blog7.jpg';

import post1 from './images/post1.jpg';
import post2 from './images/post2.jpg';
import post3 from './images/post3.jpg';
import slideContentImg from './images/slide.jpg';

export function Blog() {
  return (
    <div className='blog-container'>
      <h2 className='page-title'>Bài viết</h2>
      <div className='blog-row flex'>
        <div className='blog-right-section'>
          <div className='blog_sidebar'>
            <div className='sidebar_block'>
              <div className='sidebar_heading'>
                <h3>tìm kiếm</h3>
                <img src={footerUnderline} alt='image' />
              </div>
              <div className='sidebar_search'>
                <input type='text' placeholder='Nhập tìm kiếm ở đây' />
                <a href='javascript:;'>
                  <i className='fa fa-search' aria-hidden='true' />
                </a>
              </div>
            </div>
            <div className='sidebar_block'>
              <div className='sidebar_heading'>
                <h3>thể loại</h3>
                <img src={footerUnderline} alt='image' />
              </div>
              <div className='sidebar_category'>
                <ul>
                  <li>
                    <a href='javascript:;'>
                      trang trại hữu cơ<span>(12)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      mù tạt hữ cơ tươi<span>(16)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      Ớt chuông xanh hữu cơ<span>(156)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      Nuôi trồng thủy sản<span>(260)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      canh tác thuận tự nhiên<span>(96)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      Nông nghiệp bảo tồn<span>(12)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='sidebar_block'>
              <div className='sidebar_heading'>
                <h3>bài viết gần đây</h3>
                <img src={footerUnderline} alt='image' />
              </div>
              <div className='sidebar_post'>
                <ul>
                  <li>
                    <div className='post_image'>
                      <img src={post1} alt='image' />
                    </div>
                    <div className='post_content'>
                      <p>ngày 06 tháng 02, 2020</p>
                      <a href='blog_single.html'>Trở về tuổi thơ bên trang trại nho nhỏ.</a>
                    </div>
                  </li>
                  <li>
                    <div className='post_image'>
                      <img src={post2} alt='image' />
                    </div>
                    <div className='post_content'>
                      <p>ngày 08 tháng 11, 2021</p>
                      <a href='blog_single.html'>Những buổi đầu canh tác thuận tự nhiên.</a>
                    </div>
                  </li>
                  <li>
                    <div className='post_image'>
                      <img src={post3} alt='image' />
                    </div>
                    <div className='post_content'>
                      <p>ngày 04 tháng 12, 2022</p>
                      <a href='blog_single.html'>Thói quen uống cà phê abrica và robusta của người Việt.</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='sidebar_block'>
              <div className='sidebar_heading'>
                <h3>lưu trữ</h3>
                <img src={footerUnderline} alt='image' />
              </div>
              <div className='sidebar_category'>
                <ul>
                  <li>
                    <a href='javascript:;'>
                      tháng 8 2020<span>(12)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      tháng chín 2020<span>(16)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      tháng mười 2021<span>(156)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      tháng mười một 2021<span>(260)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      tháng mười hai 2022<span>(96)</span>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:;'>
                      tháng một 2023<span>(12)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='sidebar_block'>
              <div className='sidebar_test_slider'>
                <div className='swiper-container'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide'>
                      <div className='sidebar_test_slide'>
                        <div className='test_slide_image'>
                          <img src={slideContentImg} alt='image' />
                        </div>
                        <div className='test_slide_content'>
                          <p>“Bắt đầu từ nơi bạn đứng. Sử dụng những gì bạn có. Làm những gì bạn có thể”</p>
                          <h5>"Arthur Ashe"</h5>
                          {/* <img src={slideContentImg} alt="image" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sidebar_arrow_wrapper'>
                    <span className='sidebar_test_arrow test_left'>
                      <i className='fa fa-angle-left' aria-hidden='true' />
                    </span>
                    <span className='sidebar_test_arrow test_right'>
                      <i className='fa fa-angle-right' aria-hidden='true' />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='sidebar_block'>
              <div className='sidebar_heading'>
                <h3>các nhãn</h3>
                <img src={footerUnderline} alt='image' />
              </div>
              <div className='sidebar_tags'>
                <ul>
                  <li>
                    <a href='javascript:;'>nông nghiệp</a>
                  </li>
                  <li>
                    <a href='javascript:;'>trang trại</a>
                  </li>
                  <li>
                    <a href='javascript:;'>Hữu cơ</a>
                  </li>
                  <li>
                    <a href='javascript:;'>trứng</a>
                  </li>
                  <li>
                    <a href='javascript:;'>sữa</a>
                  </li>
                  <li>
                    <a href='javascript:;'>nuôi trồng thủy sản</a>
                  </li>
                  <li>
                    <a href='javascript:;'>xanh</a>
                  </li>
                  <li>
                    <a href='javascript:;'>vườn</a>
                  </li>
                  <li>
                    <a href='javascript:;'>trang trại bò sữa</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-left-section'>
          <div className='blog-section'>
            <div className='agri-blog-image'>
              <img src={blogImg6} alt='image' />
              <span className='agri_blog_date'>Tháng 12, 2022</span>
            </div>
            <div className='agri-blog-content'>
              <h3>
                <a href='blog_single.html'>Bếp nhà</a>
              </h3>
              <div className='blog_user'>
                <div className='user_name'>
                  <img src={user1} alt='image' />
                  <a href='javascript:;'>
                    <span>Mai Khanh</span>
                  </a>
                </div>
                <div className='comment_block'>
                  <span>
                    <i className='fa fa-comments-o' aria-hidden='true' />
                  </span>
                  <a href='javascript:;'>25 bình luận</a>
                </div>
              </div>
              <p>
                Ngày ấy nghèo khó, nên cái bếp bao giờ cũng là người bạn rất thân của mỗi gia đình. Cái bếp cháy lên trong những sớm nắng, chiều mưa... Dường như bếp lửa luôn nhìn rất rõ những gương mặt thấp thỏm âu lo của mẹ cha vì cảnh nhà túng cơm, thiếu áo. Bếp lửa nhìn rõ những lúc buồn, lúc vui khi mùa vụ đã xong với những nụ cười hay nước mắt. Bếp của mỗi gia đình đôi khi chỉ là ba ông táo tự làm bằng đất đem trộn với vỏ trấu, rồi đem phơi khô. Ai khéo hơn một chút thì nắn đất thành cái cà ràng, có ba chân dính với nhau, như vậy sẽ dễ để nồi lên nấu hơn. Người có điều kiện hơn thì không
                tự tay làm mà ra chợ mua về. Bếp bán ở chợ đẹp hơn, nhưng hồi xưa ít có chỗ bán, nên cái bếp nhà làm được sử dụng nhiều. Cuộc sống của mỗi gia đình dù có khác nhau, nhưng cái bếp thì thường giống nhau ở chỗ đều nấu bằng củi tạp đủ các loại cây kiếm được xung quanh nhà. Và cũng giống nhau ở chỗ hầu như ba bên bốn phía đều bị ám khói đen ngòm vì nhiều ngày nhiều tháng cứ đun, cứ đốt.
              </p>
              <a href='javascript:;'>
                đọc thêm{' '}
                <span>
                  <i className='fa fa-long-arrow-right' aria-hidden='true' />
                </span>
              </a>
            </div>
          </div>
          <div className='blog-section'>
            <div className='agri-blog-image'>
              <img src={blogImg5} alt='image' />
              <span className='agri_blog_date'>Tháng 5, 2020</span>
            </div>
            <div className='agri-blog-content'>
              <h3>
                <a href='blog_single.html'>Xuân trên quê hương</a>
              </h3>
              <div className='blog_user'>
                <div className='user_name'>
                  <img src={user2} alt='image' />
                  <a href='javascript:;'>
                    <span>Văn Đoàn</span>
                  </a>
                </div>
                <div className='comment_block'>
                  <span>
                    <i className='fa fa-comments-o' aria-hidden='true' />
                  </span>
                  <a href='javascript:;'>33 bình luận</a>
                </div>
              </div>
              <p>
                Quê hương đối với tôi như một đấng sinh thành thứ hai vậy.Đôi lúc dại khờ tôi chẳng thể định nghĩa được hai từ thiêng liêng ấy nữa.Nhưng tôi chỉ biết quê hương là nơi sinh ra của tôi, nơi mà tôi biết được mọi thứ: Biết được nỗi nhọc nhằn của người mẹ trước những ngày nắng gắt ban phát những tia nắng tuy nóng nhưng ấm ám xưống vạn vật làm cho mọi sự sống trỗi dậy, biết được tháng ngày gian nan của người cha khi cong lưng trên chiếc xe đạp đưa tôi đến trường vào lúc mà những hạt mưa thi nhau tưới mát ruộng đồng, ban cho mọi sự sống một bộ mặt mới mẽ: xinh tươi hơn, tràn đầy nhựa
                sống hơn.
              </p>
              <a href='javascript:;'>
                đọc thêm{' '}
                <span>
                  <i className='fa fa-long-arrow-right' aria-hidden='true' />
                </span>
              </a>
            </div>
          </div>
          <div className='blog-section'>
            <div className='agri-blog-image'>
              <img src={blogImg7} alt='image' />
              <span className='agri_blog_date'>Tháng 11, 2021</span>
            </div>
            <div className='agri-blog-content'>
              <h3>
                <a href='blog_single.html'>Hương vị ngày tết</a>
              </h3>
              <div className='blog_user'>
                <div className='user_name'>
                  <img src={user3} alt='image' />
                  <a href='javascript:;'>
                    <span>LaNhu</span>
                  </a>
                </div>
                <div className='comment_block'>
                  <span>
                    <i className='fa fa-comments-o' aria-hidden='true' />
                  </span>
                  <a href='javascript:;'>77 bình luận</a>
                </div>
              </div>
              <p>
                Với người Việt, Tết là ngày để sum họp, là ngày báo hiếu, là ngày khởi sự cho muôn vàn những điều tốt đẹp, sung túc và phát tài. Là ngày, mà dù ai đi xa, cũng nhớ để trở về nguồn cội, để tưới mát tâm hồn mình trong hương sắc tân niên rất đỗi linh thiêng của tết cổ truyền. Tết Nguyên Đán là dịp lễ lớn, quan trọng của người Việt Nam từ trước đến nay, hòa cùng với văn hóa Tết âm lịch của các nước phương Đông. Đây là khoảng thời gian được xem là sum vầy, ấm áp nhất của cả một năm. Với chừng ấy thời gian biến thiên, có nhiều nét đón xuân cổ truyền đã dần mai một. Nhưng hương vị tết
                xưa, hẳn vẫn luôn nằm sâu thẳm trong tâm khảm người Việt.
              </p>
              <a href='javascript:;'>
                đọc thêm{' '}
                <span>
                  <i className='fa fa-long-arrow-right' aria-hidden='true' />
                </span>
              </a>
            </div>
          </div>
          <div className='blog_pagination_section'>
            <ul>
              <li className='blog_page_arrow'>
                <a href='javascript:;'>
                  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='10px' height='15px'>
                    <path fillRule='evenodd' fill='rgb(112, 112, 112)' d='M0.324,8.222 L7.117,14.685 C7.549,15.097 8.249,15.097 8.681,14.685 C9.113,14.273 9.113,13.608 8.681,13.197 L2.670,7.478 L8.681,1.760 C9.113,1.348 9.113,0.682 8.681,0.270 C8.249,-0.139 7.548,-0.139 7.116,0.270 L0.323,6.735 C0.107,6.940 -0.000,7.209 -0.000,7.478 C-0.000,7.747 0.108,8.017 0.324,8.222 Z' />
                  </svg>
                  <span>trước</span>
                </a>
              </li>
              <li>
                <a href='javascript:;'>01</a>
              </li>
              <li>
                <a href='javascript:;'>02</a>
              </li>
              <li>
                <a href='javascript:;'>....</a>
              </li>
              <li>
                <a href='javascript:;'>12</a>
              </li>
              <li>
                <a href='javascript:;'>13</a>
              </li>
              <li className='blog_page_arrow'>
                <a href='javascript:;'>
                  <span>tiếp</span>
                  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='19px' height='25px'>
                    <path fillRule='evenodd' fill='rgb(112, 112, 112)' d='M13.676,13.222 L6.883,19.685 C6.451,20.097 5.751,20.097 5.319,19.685 C4.887,19.273 4.887,18.608 5.319,18.197 L11.329,12.478 L5.319,6.760 C4.887,6.348 4.887,5.682 5.319,5.270 C5.751,4.861 6.451,4.861 6.884,5.270 L13.676,11.735 C13.892,11.940 14.000,12.209 14.000,12.478 C14.000,12.747 13.892,13.017 13.676,13.222 Z' />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
