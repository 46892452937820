
export const isNumber = (val) => typeof(val) === 'number'

export const isString = (val) => typeof(val) === 'string'

export const isImageSrc = (url) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url) || typeof url === 'string' && url.includes?.('data:image')

export const isDate = value => value instanceof Date && !isNaN(value)

export const debounce = (action, timeout) => {
  let timer = null
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => action(...args), timeout)
  }
}

//------------------ Responsive helpers ------------------

export const isMobie = () => window.screen.width < 768;

//------------------ JSX helpers ------------------

export const findImage = (node) => {
  if (!node?.props?.children) return null;
  
  let val;
  let children = node.props.children;
  if(!Array.isArray(children)) children = [children];
  
  for(let child of children) {
    if (child.type === 'img') return child.props.src;
    val = findImage(child);
    if(val) return val;
  }
}

//-------------------- Array helpers --------------------

export const moveItems = (arr=[], fromIndex=0, count=1, toIndex=arr.length-1) => {
  arr.splice(toIndex, 0, ...arr.splice(fromIndex,count));
}

//------------------------------------------------------