import { API } from "@api";

export const ProductApi = {
  getProducts: async (params) => {
    const { data, total } = await API.get('/products', { params });
    const dataProcessed = data?.map(p => {
      const { price, images } = p.variants?.find(v => v.isDefault) ?? {};
      return {
        ...p,
        price,
        img: images?.[0]
      };
    });
    return { data: dataProcessed, total };
  },
  getProductDetail: async (id) => {
    const { data } = await API.get(`/products/${id}`);
    return data;
  }
};
