import { noAction } from '..'
import { Button } from '..'
import { Icon } from '../icon/icon'

import './popup.css'

export function Popup({ visible=true, title='', content='', 
  acceptText='Đồng ý', dangerAccept=false, successAccept=false, onAccept=noAction, 
  cancelText='', onCancel=noAction,
  closeOnOutside=false, onClose=noAction
}) {

  const accept = () => {
    onAccept();
    onClose();
  }

  const cancel = () => {
    onCancel();
    onClose();
  }

  return <div className={`popup-wrapper ${visible ? 'open' : ''}`} onClick={closeOnOutside ? onClose : noAction}>
    <div className='popup border border-radius' onClick={e=>e.stopPropagation()}>
      <div className="title flex center m-b-6">
        <span className='flex-1 bold text-center'>{title}</span>
        <Icon className='x-icon' width={'1rem'} height={'1rem'} onClick={onClose}/>
      </div>
      <div className="body">{content}</div>
      <div className="actions m-t-6">
        {cancelText ? <Button onClick={cancel}>{cancelText}</Button> : <div/>}
        <Button type={dangerAccept ? 'danger' : successAccept ? 'success' : 'primary'} onClick={accept}>{acceptText}</Button>
      </div>
    </div>
  </div>
}