import React from "react";

import './main.css';

export const Main = props => {
    return <main>
        <div className="container">
            {props.children}
        </div>
    </main>
}