import React, { useEffect } from 'react';
import { Carousel } from '@nmk-ui-components';
import { isMobie } from '@nmk-ui-utils';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import './home.css';

import underlineImg from './images/underline3.png';
import luaImg from './images/lua.jpg';
import cuaImg from './images/cua.jpg';
import cua1Img from './images/cua_1.jpg';
import cua2Img from './images/cua_2.jpg';

const slides = [
  {
    title: 'Gốc Việt',
    description: <span><div>Nông phẩm sạch</div><div>vì sức khỏe bà con.</div></span>,
    image: luaImg,
  },
  {
    title: 'Cua biển Cà Mau',
    description: 'Nhờ được nuôi trong môi trường tự nhiên nên cua vận động nhiều, thịt săn chắc, không nhiễm độc hay bị bơm chích tạp chất. Thịt cua biển Cà Mau vừa chắc, vừa thơm, vừa ngọt, vừa bùi và gạch cua thì béo ngậy không chê vào đâu được.',
    image: cuaImg,
  },
  {
    title: 'Đánh bắt cua',
    description: 'Ngoài việc đóng đáy, ngư dân còn giăng câu, đặt rập, đào hang để bắt cua. Cua thiên nhiên nhiều nhất ở vùng rừng ngập mặn Cà Mau. Khoảng thời gian từ tháng 7 đến tháng 8 âm lịch hàng năm là mua cua hội, cua tìm đến bạn tình để trao duyên. Thời điểm này cua rất ngon, đầy gạch và chắc thịt.',
    image: cua1Img,
  },
  {
    title: 'Chế biến',
    description: 'Cua biển Cà Mau được xem loại cua biển là ngon nhất nước bởi hàm lượng mỡ thấp, protein cao, dồi dào về khoáng chất và vitamin. Do đó, nhiều nhà hàng, quán ăn hải sản nào tại các thành phố lớn trong cả nước dù bán cua biển nơi đâu, hay ở xứ nào cũng đều quảng cáo là cua biển Cà Mau.',
    image: cua2Img,
  },
];

const bestSellerProducts = [
  {
    name: 'Cabbage',
    price: 65000,
    img: 'https://images.unsplash.com/photo-1507484467459-0c01be16726e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
  },
  {
    name: 'Blueberry',
    price: 123000,
    img: 'https://images.unsplash.com/photo-1566400628146-ae8f27849e90?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
  },
  {
    name: 'Lemon',
    price: 35000,
    img: 'https://images.unsplash.com/photo-1579868439700-a4e86cd776da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
  },
  {
    name: 'Strawberry',
    price: 179000,
    img: 'https://images.unsplash.com/photo-1488551511020-571c741f122a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80',
  },
  {
    name: 'Orange',
    price: 98000,
    img: 'https://images.unsplash.com/photo-1607116692929-ece4c84a5992?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
  },
];

const renderSlide = (s) => {
  const slideStyle = {
    backgroundImage: `url(${s.image})`,
    backgroundSize: 'cover',
  };
  return <div key={s.image} className='full-width full-height flex center' style={slideStyle}>
    <div className='text-intro font-size-xl5'>
      <div className='flex column center gap-4'>
        <div className='title m-b-5'>{s.title}</div>
        <div className='description'>{s.description}</div>
        {/* <Button type='success'>Xem sản phẩm</Button> */}
      </div>
    </div>
  </div>
}

export const Home = props => {
  useEffect(() => window.scrollTo(0,0), [])
  return (
    <div className='home-page'>
      <div className='scroll_down'>
        <span></span>
        <p>Xem thêm</p>
      </div>
      <div className='blur_bg'>
        <section id='slider-main'>
          <Carousel visibleCount={1} isLoop transition='1s' height='100%'>
            {slides.map(renderSlide)}
          </Carousel>
        </section>
        {/* START - SẢN PHẨM MỚI */}
        <section className='new-products'>
          <div className='shop_slider'>
            <div className='product_heading'>
              <div className='clv_heading'>
                <h3>Sản phẩm mới</h3>
                <div className='clv_underline'>
                  <img src={underlineImg} alt='image' />
                </div>
                <span>Các sản phẩm bán mới nhập về trong tháng vừa qua.</span>
              </div>
            </div>
            <Carousel visibleCount={isMobie() ? 1 : 3} isLoop gap='20px' className='m-t-5'>
              {bestSellerProducts.map(p => (
                <div key={p.name}>
                  <div className='shop_slide'>
                    <div className='item_image'>
                      <img src={p.img} alt='image' className='img-fluid' />
                    </div>
                    <h5>{p.name}</h5>
                    <h6>{p.price}</h6>
                    <div className='item_overlay'>
                      <input type='checkbox' id='wishlist1' />
                      <label htmlFor='wishlist1'>
                        <span className='wish_icon'>
                          <i className='fa fa-heart-o' aria-hidden='true'></i>
                        </span>
                        <span className='wish_icon'>
                          <i className='fa fa-heart' aria-hidden='true'></i>
                        </span>
                      </label>
                      <h5>{p.name}</h5>
                      <h6>{p.price}</h6>
                      <a href='#' className='shop_btn p-x-2'>
                        Xem sản phẩm
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
        {/* END - sản phẩm mới */}
        {/* START - Sản phẩm bán chạy */}
        <section className='best_selling_wrapper'>
          <div className='product_heading'>
            <div className='clv_heading'>
              <h3>Sản phẩm bán chạy</h3>
              <div className='clv_underline'>
                <img src={underlineImg} alt='.' />
              </div>
              <span>Những sản phẩm được khách hàng tin tưởng lựa chọn và tin dùng trong tháng vừa qua.</span>
            </div>
          </div>
          <Carousel visibleCount={isMobie() ? 1 : 3} isLoop gap='20px' className='shop_slider m-t-5'>
            {bestSellerProducts.map(p => (
              <div key={p.name}>
                <div className='shop_slide'>
                  <div className='item_image'>
                    <img src={p.img} alt='.' className='img-fluid' />
                  </div>
                  <h5>{p.name}</h5>
                  <h6>{p.price}</h6>
                  <div className='item_overlay'>
                    <input type='checkbox' id='wishlist1' />
                    <label htmlFor='wishlist1'>
                      <span className='wish_icon'>
                        <i className='fa fa-heart-o' aria-hidden='true'></i>
                      </span>
                      <span className='wish_icon'>
                        <i className='fa fa-heart' aria-hidden='true'></i>
                      </span>
                    </label>
                    <h5>{p.name}</h5>
                    <h6>{p.price}</h6>
                    <a href='#' className='shop_btn p-x-2'>
                      Xem sản phẩm
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </section>
        {/* END - Sản phẩm bán chạy */}
      </div>
    </div>
  );
};
