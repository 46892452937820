import React from 'react'

import './about.css'

import about1 from './images/about2.jpg'
import underlineImg from './images/underline.png'
import underline3Img from './images/underline3.png'

import eventImg from './images/event_service.png'
import eggImg from './images/egg_service.png'
import farmImg from './images/farm_service.png'
import hensImg from './images/hens_service.png'
import serviceUnderline from './images/service_underline.png'

import serviceGirlImg from './images/service_girl1.jpg'

import teamImg1 from './images/teamImg1.jpg'
import teamImg2 from './images/teamImg2.png'
import teamImg3 from './images/teamImg3.png'
import teamImg4 from './images/teamImg4.png'

export function About() {

  return (
    <div className='about_wrapper'>
      <div className='clv_about_wrapper'>
        <div className='about_blur_bg'></div>
        <div className='about_container'>
          <div className='about_row'>
            <div className='about_section'>
              <div className='about_img'>
                <img src={about1} alt='image' />
              </div>
            </div>
            <div className='about_section'>
              <div className='about_content'>
                <div className='about_heading'>
                  <h2>
                    Chào mừng bạn đến với <span>Gốc Việt</span>
                  </h2>
                  <h5>Nơi mua bán và phân phối các sản phẩm nông sản sạch tới tay người Việt</h5>
                  <div className='clv_underline'>
                    <img src={underlineImg} alt='image' />
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatiomco laboris nisi ut aliquip ex ea commodo consequat. </p>
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fuiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui offi deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error eivoluptatem accusantium doloremque laudantium.</p>
                <div className='video_block'>
                  <div className='video_btn'>
                    <a href='https://www.youtube.com/watch?v=rdBF5seCfwg&ab_channel=WhamVEVO' className='play_video'>
                      <span className='pulse'>
                        <i className='fa fa-play' aria-hidden='true' />
                      </span>
                      <div className='watch_video_text'>Xem video</div>
                    </a>
                  </div>
                  <a href='javascript:;' className='clv_btn'>
                    đọc thêm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='clv_service_wrapper'>
        <div className='about_container'>
          <div className='about_row justify-content-center'>
            <div className='about_section'>
              <div className='about_services_heading'>
                <h3>Khám phá các dịch vụ chúng tôi cung cấp</h3>
                <div className='clv_underline'>
                  <img src={underline3Img} alt='image' />
                </div>
                <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dole magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p>
              </div>
            </div>
          </div>
          <div className='service_main_wrapper'>
            <div className='about_row'>
              <div className='about_service_column_eight'>
                <div className='about_row'>
                  <div className='about_section'>
                    <div className='service_block'>
                      <span />
                      <div className='service_icon'>
                        <img src={eventImg} alt='image' />
                      </div>
                      <h4>giáo dục &amp; sự kiện</h4>
                      <div className='clv_underline'>
                        <img src={serviceUnderline} alt='image' />
                      </div>
                      <p>Dolor sit amet consectetur adipiscing elieri sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </div>
                  <div className='about_section'>
                    <div className='service_block'>
                      <span />
                      <div className='service_icon'>
                        <img src={eggImg} alt='image' />
                      </div>
                      <h4>trứng hữu cơ</h4>
                      <div className='clv_underline'>
                        <img src={serviceUnderline} alt='image' />
                      </div>
                      <p>Dolor sit amet consectetur adipiscing elieri sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </div>
                  <div className='about_section'>
                    <div className='service_block'>
                      <span />
                      <div className='service_icon'>
                        <img src={farmImg} alt='image' />
                      </div>
                      <h4>Trang trại chúng tôi</h4>
                      <div className='clv_underline'>
                        <img src={serviceUnderline} alt='image' />
                      </div>
                      <p>Dolor sit amet consectetur adipiscing elieri sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </div>
                  <div className='about_section'>
                    <div className='service_block'>
                      <span />
                      <div className='service_icon'>
                        <img src={hensImg} alt='image' />
                      </div>
                      <h4>gà giống tốt nhất</h4>
                      <div className='clv_underline'>
                        <img src={serviceUnderline} alt='image' />
                      </div>
                      <p>Dolor sit amet consectetur adipiscing elieri sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about_service_column_four'>
                <div className='service_girl_img'>
                  <img src={serviceGirlImg} alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='clv_team_wrapper'>
        <div className='about_container'>
          <div className='about_row justify-content-center'>
            <div className='about_section'>
              <div className='team_heading'>
                <h3>đội ngũ của chúng tôi</h3>
                <div className='clv_underline'>
                  <img src={underline3Img} alt='image' />
                </div>
                <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dole magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p>
              </div>
            </div>
          </div>
          <div className='about_row'>
            <div className='about_team_full'>
              <div className='team_section'>
                <div className='about_row'>
                  <div className='about_team_column_three'>
                    <div className='team_block'>
                      <div className='team_image'>
                        <img src={teamImg1} alt='image' />
                        <div className='social_overlay'>
                          <p>Bạn có thể tham gia với chúng tôi</p>
                          <ul>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-facebook' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-twitter' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-linkedin' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-youtube-play' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='team_details'>
                        <div className='team_name'>
                          <h3>tree</h3>
                          <p>Superman</p>
                          <span className='divider' />
                          <a href='javascript:;'>plant@gmail.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='about_team_column_three'>
                    <div className='team_block'>
                      <div className='team_image'>
                        <img src={teamImg2} alt='image' />
                        <div className='social_overlay'>
                          <p>Bạn có thể tham gia với chúng tôi</p>
                          <ul>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-facebook' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-twitter' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-linkedin' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-youtube-play' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='team_details'>
                        <div className='team_name'>
                          <h3>Văn đoàn</h3>
                          <p>Culi Dev</p>
                          <span className='divider' />
                          <a href='javascript:;'>vandoan@gmail.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='about_team_column_three'>
                    <div className='team_block'>
                      <div className='team_image'>
                        <img src={teamImg3} alt='image' />
                        <div className='social_overlay'>
                          <p>Bạn có thể tham gia với chúng tôi</p>
                          <ul>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-facebook' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-twitter' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-linkedin' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-youtube-play' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='team_details'>
                        <div className='team_name'>
                          <h3>Ocean Man</h3>
                          <p>Funny Man</p>
                          <span className='divider' />
                          <a href='javascript:;'>oceanman@gmail.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='about_team_column_three'>
                    <div className='team_block'>
                      <div className='team_image'>
                        <img src={teamImg4} alt='image' />
                        <div className='social_overlay'>
                          <p>Bạn có thể tham gia với chúng tôi</p>
                          <ul>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-facebook' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-twitter' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-linkedin' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href='javascript:;'>
                                <span>
                                  <i className='fa fa-youtube-play' aria-hidden='true' />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='team_details'>
                        <div className='team_name'>
                          <h3>Mai Khanh</h3>
                          <p>Pro Checker</p>
                          <span className='divider' />
                          <a href='javascript:;'>maikhanh@gmail.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
