import { useEffect, useState } from 'react';
import { Svg, noAction } from '..';
import { debounce } from '../../assets/js/functions';

import noImage from '../../assets/image/no_image.png';

import './input.css';

export function Input({ className = '', type = 'text', label = '', placeholder = '', onChange = noAction, debounceTime = 200, ...props }) {
  const { value } = props;

  const [innerValue, setInnerValue] = useState('');
  const [filePath, setFilePath] = useState(noImage);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const change = (e) =>
  {
    const { value, checked } = e.target;
    const handleChange = 'text number email password search tel url'.includes(type) && debounceTime
      ? debounce(onChange, debounceTime)
      : onChange;
    'checkbox radio'.includes(type) ? handleChange?.(checked) : handleChange?.(value);
  }

  const changeImg = e => {
    let file = e.target.files[0]; // FileList object
    let reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = evt => {
      setFilePath(evt.target.result);
      onChange?.(evt.target.result, e);
    };

    reader.readAsDataURL(file); // Read in the image file as a data URL.
  };

  const clickLabel = () => {
    if (type === 'checkbox') onChange?.(!value)
    else if (type === 'radio') onChange?.(!value)
  };

  return (
    <div className={`input-container ${type} ${className}`}>
      {label && <label onClick={clickLabel}>{label}</label>}
      {'text number date email password search tel url color'.includes(type) ? (
        <div className={`input-wrapper ${type}`}>
          <input {...props} value={innerValue} type={type} placeholder={placeholder} onChange={change} />
        </div>
      ) : 'checkbox radio'.includes(type) ? (
        <input {...props} type={type} checked={value} onChange={change} />
      ) : type === 'textarea' ? (
        <textarea {...props} onChange={change} />
      ) : type === 'image file' ? (
        <div className='input-image-file'>
          <input {...props} type='file' onChange={changeImg} accept='image/png, image/jpeg' />
          <img src={filePath} />
        </div>
      ) : type === 'range' ? (
        <input {...props} type={type} onChange={change} />
      ) : (
        <input {...props} type={type} onChange={change} />
      )}
    </div>
  );
}
