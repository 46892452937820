import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { noAction } from '../../global/functions/func'
import { Icon } from '@components'

import './menu.css'

export function Menu ({items=[], rightItems=[]}) {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const handleTouchOverlay = (e) => {
    setOpen(false);
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className='menu-wrapper'>
      <div className={`menu-bar ${isOpen ? 'open' : ''}`}>
        {
          items.map(item => {
            const onClick = item.path ? ()=>navigate(item.path) : noAction;
            const handleClickItem = (e) => {
              setOpen(false);
              onClick(e);
            }
            const content = item.content ?? <span>{item.text}</span>
            return <div key={item.key} className='menu-item' onClick={handleClickItem}>
              {item.icon}
              {content}
            </div>
          })
        }
      </div>
      <div className='menu-icon' onClick={()=>setOpen(true)}>
        <Icon name='bars' size={30}/>
      </div>
      <div className={`menu-overlay ${isOpen ? 'open' : ''}`} onTouchEnd={handleTouchOverlay}/>
    </div>
  )
}
