import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GTAG_KEY } from "../enums/gtag";

export function useGtagPageview() {
  const location = useLocation();
  useEffect(() => {
    window.gtag('config', GTAG_KEY, {
      page_path: location.pathname + location.search,
    });
  }, [location]);
}