
import { ReactComponent as Bars } from './svg/bars-solid.svg'
import { ReactComponent as Cart } from './svg/cart-shopping-solid.svg'
import { ReactComponent as Phone } from './svg/phone-solid.svg'
import { ReactComponent as Message } from './svg/comment-dots-solid.svg'
import { ReactComponent as Notification } from './svg/bell-solid.svg'
import { ReactComponent as User } from './svg/user-solid.svg'
import { ReactComponent as Location } from './svg/location-dot-solid.svg'
import { ReactComponent as Mail } from './svg/envelope-solid.svg'
import { ReactComponent as Search } from './svg/magnifying-glass-solid.svg'

import { ReactComponent as Up } from './svg/chevron-up-solid.svg'
import { ReactComponent as Down } from './svg/chevron-down-solid.svg'

import './icon.css'

export const Icon = ({ name, size='', width='20px', height='20px', onClick=null }) => {
  const wrapperStyle = { width: size || width, height: size || height }
  return <div className='icon-wrapper' style={wrapperStyle} onClick={onClick}>
  {
    name === 'bars' ? <Bars/> 
    : name === 'cart' ? <Cart/> 
    : name === 'phone' ? <Phone/>
    : name === 'message' ? <Message/>
    : name === 'notification' ? <Notification/>
    : name === 'user' ? <User/>
    : name === 'address' ? <Location/>
    : name === 'email' ? <Mail/>
    : name === 'search' ? <Search/>
    : name === 'up' ? <Up color="#26602e"/>
    : name === 'down' ? <Down/>
    : null
  }
  </div> 
}
