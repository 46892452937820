export const GTAG_KEY = 'G-KLKQYE9QT4';

export const GTAG_TYPES = {
  EVENT: 'event',
};

export const GTAG_CATEGORIES = {
  ECOMMERCE: 'ecommerce',
};

export const GTAG_EVENTS = {
  PRODUCT_VIEW: 'product_view',
  PRODUCT_ADD: 'product_add',
};