import { API } from "@api";

export const ProductGroupApi = {
  getProductGroups: async () => {
    const { data, total } = await API.get('/product-groups');
    const dataProcessed = [{
      _id: "",
      description: "Toàn bộ nhóm sản phẩm",
      name: "Tất cả",
    }];
      for(let i = 0; i < data.length - 1; i++) {
        data[i].children = [];
        for(let j = i + 1; j < data.length; j++) {
          if(data[i]._id === data[j].parent) {
            data[i].children.push({ ...data[j], isSelected: false });
          }
        }
        if (!data[i].parent) dataProcessed.push({ ...data[i], isSelected: false })
      }
      return { data: dataProcessed, total };
  },
  getProducts: async (groupId, params) => {
    const { data, total } = await API.get(`/product-groups/${groupId}/products`, { params });
    return { data, total };
  }
};
